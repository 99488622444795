import React from "react";
import { useEffect } from "react";
import styles from "./help.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import { config } from "../../../../config/global";
import { helpDataFailure, helpDataSuccess } from "../../slice/header/helpSlice";
import FullImageCard from "../../../../shared/card-component/card-full-image";
import useScreenSize from "../../../../utils/hook/useScreenSize";

export const Help = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state?.osciReducer?.help);
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 1080;

  // Redirect to the given URL on click
  const handleMenuClick = (url: string) => {
    if (url) {
      window.location.href = url;
    }
  };

  // Dispatch help data request
  useEffect(() => {
    const API_URL = config.TEAMSITE_HELP_ENDPOINT;
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: API_URL,
      isTeamsite: true,
      successAction: helpDataSuccess,
      errorAction: helpDataFailure,
    });
  }, [dispatch]);

  return (
    <>
      {isDesktopView && (
        <div className={styles.fullHelpContainer}>
          <div className={`global-content-padding ${styles.helpContainer}`}>
            <div className={styles.leftSideText}>
              <div className={styles.row}>
                <div className={styles.innerRow}>
                  {data?.helpDetails && (
                    <span className={styles.helpText}>
                      {data?.helpDetails?.mainHelpName}
                    </span>
                  )}
                  {data?.helpDetails?.mainHelpGroup?.[0]?.mainHelpNavigation.map(
                    (item: any) => (
                      <span
                        key={item?.mainHelpNavigationId}
                        className={styles.optionText}
                        onClick={() =>
                          handleMenuClick(item?.mainHelpNavigationUrl)
                        }
                      >
                        {item?.mainHelpNavigationName}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className={styles.rightSideImg}>
              <div className={styles.innerImg}>
                {data?.helpDetails?.mainHelpGroup?.map((group: any) =>
                  group?.mainHelpNavigation?.map(
                    (navItem: any) =>
                      navItem?.showCard &&
                      navItem?.data.map((cardData: any, index: number) => (
                        <FullImageCard
                          key={index}
                          data={{
                            imageUrl: cardData?.bgImageUrl,
                            heading: cardData?.headingText,
                            buttonText: cardData?.buttonText,
                            redirectionUrl: cardData?.redirectionUrl,
                          }}
                        />
                      ))
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import { ListGroup } from "react-bootstrap";
import styles from "../../../../shared/tg-expandable-list/tg-expandable-list.module.scss";

export const ExpandableHelp = (props: any) => {
  // Redirect to the given URL on click
  const handleMenuClick = (url: string) => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div className={styles["expandable-list-body"]}>
      {props?.expandableList?.map((navItem: any) => (
        <ListGroup
          key={navItem?.mainHelpNavigationId}
          className={styles["expandable-list-wrapper"]}
        >
          <ListGroup.Item
            key={navItem?.mainHelpNavigationId}
            className={styles["expandable-list-item"]}
            id={navItem?.mainHelpNavigationId?.toString()}
          >
            <span
              onClick={() => {
                handleMenuClick(navItem?.mainHelpNavigationUrl);
              }}
            >
              {navItem?.mainHelpNavigationName}
            </span>
          </ListGroup.Item>
        </ListGroup>
      ))}
    </div>
  );
};

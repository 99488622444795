import { t } from "i18next";
import styles from "./download-app.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import React from "react";
import TGIcon from "../../../../shared/tg-icon";

interface DownloadAppProps {
  isDesktop: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  setQrCode: React.Dispatch<React.SetStateAction<string>>;
  setShowDownloadAppModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadApp: React.FC<DownloadAppProps> = ({
  isDesktop,
  isIOS,
  isAndroid,
  setQrCode,
  setShowDownloadAppModal,
}) => {
  const appStore = useSelector(
    (state: RootState) => state?.osciReducer?.app.appStoreDetails
  );

  return (
    <div className={styles["main-container"]}>
      <div className={styles["backgroundImage"]}>
        <p className={styles["title-text"]}>
          {t("label_download_thai_mobile_app")}
        </p>
        <p className={styles["title-description"]}>
          {t("label_download_thai_mobile_description")}
        </p>
        <div
          className={styles["get-the-app-btn"]}
          onClick={() => {
            if (isDesktop) {
              setQrCode("App Store");
              setShowDownloadAppModal(true);
            } else if (isIOS) {
              window.open(process.env.REACT_APP_STORE_URL);
            } else {
              window.open(process.env.REACT_PLAY_STORE_URL);
            }
          }}
        >
          <div>{t("label_get_the_app")}</div>
          <TGIcon icon="arrow-right-sm" size={""} fillColor={"#684b9b"} />
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
